import { FC, SyntheticEvent, memo } from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { MessageSquare } from 'react-feather';

import { CanonicalContent, Comment, Contact, Movie, Share, TvShow, Unfurl, WatchStateItem } from '@/generated/graphql';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { Avatar } from '@/components/Avatar';
import { DecoratedText, DecoratedTextProps } from '@/components/DecoratedText';
import { ChatOverlay, ChatOverlayProps } from '@/components/ChatOverlay';
import { UserName } from '@/components/UserName';
import { TinyItem, TinyItemProps } from '@/views/feed/items/TinyItem';
import { Button } from '@/components/Button';
import { useNavigationActions } from '@/hooks/history';
import { StringParam, useQueryParam } from 'use-query-params';


export type AddedWatchStateProps = {
  share: Pick<Share, '_id'> & {
    unfurl: Pick<Unfurl, '_id' | 'title'>,
  } & TinyItemProps['share'],
  canonicalContent: Pick<CanonicalContent, '_id'> & {
    movie?: Pick<Movie, '_id' | 'title'>,
    tvshow?: Pick<TvShow, '_id' |'title'>,
  } & TinyItemProps['canonicalContent'],
  watchState: Pick<WatchStateItem, '_id'> & {
    comments: Array<
      Pick<Comment, '_id' | 'text' | 'language'> & {
        author: Pick<Contact, '_id'>,
        textLinks: DecoratedTextProps['links'],
        mentions: DecoratedTextProps['mentions'],
      } & ChatOverlayProps['comments'][number]
    >,
  },
  state: 'watched' | 'watchlist',
  addedBy: Pick<Contact, '_id'>,
}


export const AddedWatchState: FC<AddedWatchStateProps> = memo(function AddedWatchState({ watchState, share, canonicalContent, addedBy, state, ...rest }: AddedWatchStateProps) {
  const { track } = useAnalyticsQueued();
  const theme = useTheme();

  const { closeSheet } = useNavigationActions();
  const sheetQueryParam = "comment-chat";
  const [openChatId, setOpenChatId] = useQueryParam(sheetQueryParam, StringParam);

  const handleClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('click_canonical_addedWatchState', { category: 'canonical' });
    setOpenChatId(watchState._id);
  }

  const title = canonicalContent.movie?.title || canonicalContent.tvshow?.title || share.unfurl.title;
  const comments = watchState.comments;
  const comment = comments?.[comments.length - 1];

  return (
    <>
      <Card
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '224px',
          flex: '0 0 auto',
          padding: 1,
          borderRadius: '8px',
          backgroundColor: 'background.paper',
          border: `1px solid`,
          borderColor: 'grey.300',
        }}
        onClick={handleClick}
        {...rest}
      >
        <Box sx={{ lineHeight: 0 }}>
          <Box component='p' sx={{ m: 0, lineHeight: 'normal', lineClamp: 3, WebkitLineClamp: 3, textOverflow: 'ellipsis', overflow: 'hidden', WebkitBoxOrient: 'vertical', display: '-webkit-inline-box' }}>
            <Avatar contact={addedBy._id} size='small' sx={{ display: 'inline-block', verticalAlign: 'text-top' }} />
            <UserName component='span' userId={addedBy._id} addLink={false} sx={{ fontWeight: 500, display: 'inline', ml: '6px', mr: 0.5, lineHeight: '20px', verticalAlign: 'top' }} />
            <Box component='span' sx={{ display: 'inline', lineHeight: '20px', verticalAlign: 'top' }}>
              {state === 'watched' &&
                <>
                  watched <span style={{ fontWeight: 500 }}>{title}</span>
                </>
              }
              {state === 'watchlist' &&
                <>
                  added <span style={{ fontWeight: 500 }}>{title}</span> to their watchlist
                </>
              }
            </Box>
          </Box>

          {comment &&
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
              <Avatar contact={comment.author?._id} size='small' />
              <Box sx={{
                overflow: 'hidden',
                position: 'relative',
                flex: '0 1 auto',
              }}>
                <Box
                  sx={{
                    ml: 1,
                    px: 1,
                    py: 0.5,
                    backgroundColor: 'primary.background',
                    borderRadius: '4px',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: '16px',
                      width: 0,
                      height: 0,
                      border: '8px solid transparent',
                      borderRightColor: 'primary.background',
                      borderLeft: 0,
                      mt: '-8px',
                    }
                  }}
                >
                  <Typography
                    component="div"
                    variant='body1'
                    sx={{
                      width: '100%',
                      '& > *': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        whiteSpace: 'nowrap',
                      }
                    }}
                    lang={comment?.language}
                  >
                    {comment?.text &&
                      <DecoratedText text={comment.text} link={false} links={comment?.textLinks || undefined} mentions={comment?.mentions || undefined} splitParagraphs={false} />
                    }
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
        </Box>


        {!comment &&
          <Button
            variant='contained'
            size='small'
            fullWidth
            disableElevation
            color='grey'
            sx={{
              typography: 'body2',
              gap: '3px',
              color: theme.palette.text.secondary,
            }}
            onClick={handleClick}
          >
            <MessageSquare
              style={{
                stroke: theme.palette.text.secondary,
                strokeWidth: 1.4,
              }}
              width={16}
              height={16}
            />
            Comment
          </Button>
        }

      </Card>
      {openChatId === watchState._id &&
        <ChatOverlay comments={comments || []} watchStateId={watchState._id} onClose={(ev) => { ev?.preventDefault(); ev?.stopPropagation(); closeSheet(sheetQueryParam) }} contextColor={theme.palette.groups.public}>
          <Card
            sx={{
              position: 'relative',
              mx: 2,
              p: 1,
              mb: 1,
              mt: 5,
              '&:last-of-type': { mb: 0 },
              borderRadius: '8px',
              backgroundColor: 'background.paper',
              border: `1px solid ${theme.palette.grey['100']}`,
              overflow: 'unset',
              boxShadow: 1,
            }}
            onClick={(ev: SyntheticEvent) => ev.stopPropagation() }
          >
            <Box sx={{ display: 'flex', mb: 1, gap: 1 }}>
              <Avatar
                sx={{ flex: '0 0 32px' }}
                size={'medium'}
                contact={addedBy?._id}
                link={true}
              />
              <UserName
                userId={addedBy?._id}
                addLink={true}
                sx={{ fontWeight: 500 }}
                postfix={
                  state === 'watched' ?
                    ' watched' :
                    (state === 'watchlist' ?
                      ' added to watchlist' :
                      ''
                    )
                }
              />
            </Box>
            <TinyItem share={share} canonicalContent={canonicalContent} />
          </Card>
        </ChatOverlay>
      }
    </>
  );
});
