import { FC, memo } from 'react';
import { Box, Typography } from "@mui/material";

import { CanonicalContent, Contact, Message, Share, TvShow as TvshowType, Unfurl, Video } from '../../generated/graphql';
import { useIsWatched, useIsInWatchlist } from '../../hooks/watch-state';
import { useSession } from '../../hooks/auth';
import { useContact } from '../../hooks/use-contact';
import { truncate } from '../../utils/string';
import { canonicalLink } from '../../utils/routes';
import { createCanonicalPageType, createSharePageType } from '../../utils/json-ld';
import { Helmet } from '../../components/Helmet';
import { Layout, LayoutProps } from "./Layout";
import { Imdb } from './Imdb';
import { Providers, ProvidersProps } from './Providers';
import { Divider } from './Divider';
import { Details, DetailsProps } from './Details';
import { Post, PostProps } from './Post';
import { OtherCanonicals } from './OtherCanonicals';
import { Overview } from './Overview';
import { Cast } from './Cast';
import { Trailer } from './Trailer';
import { TypeYear } from '../../components/canonical/TypeYear';
import { Activity, ActivityProps } from './Activity';
import { Avatar } from '../../components/Avatar';
import { RatingIcon } from '../../components/icons/RatingIcon';
import { ReshareButton, ReshareButtonProps } from './ReshareButton';
import { PostLoading } from './PostLoading';
import { MoreShares } from './MoreShares';
import Delayed from '../../components/Delayed';
import { ExternalContents } from './ExternalContents';
import { LinkBox } from '../../components/LinkBox';
import { SiteindexLinks } from './SiteindexLinks';


export type TvshowProps = {
  canonical: Pick<CanonicalContent, '_id' | 'createdAt'> & ActivityProps['canonical'] & {
    type: 'tvshow',
    rating?: ActivityProps['rating'],
    ratings?: ActivityProps['ratings'],
    tvshow: Pick<TvshowType, 'title' | 'imdbRating' | 'imdbVotes' | 'imdb' | 'overview' | 'providersCountry' | 'firstAirDate' | 'homepage' | 'networks' | 'originalTitle' | 'originalLanguage'> & {
      backdrop?: LayoutProps['backdrop'],
      poster?: LayoutProps['poster'],
      video?: Pick<Video, 'title'> & LayoutProps['video'],
      createdBy: DetailsProps['creators'],
      providers: ProvidersProps['providerItems'],
      directors: DetailsProps['directors'],
      genres: DetailsProps['genres'],
      seasons: DetailsProps['seasons'],
    },
  } & ReshareButtonProps['canonical'] & Omit<PostProps['canonical'], 'movie'>,
  share?: Pick<Share, '_id'> & {
    sender?: Pick<Contact, '_id'> | null,
    unfurl: Pick<Unfurl, 'title'>,
    message?: Pick<Message, 'text'>,
    rating?: ActivityProps['rating'],
    ratings?: ActivityProps['ratings'],
  } & ReshareButtonProps['share'] & PostProps['share'] | null,
  shareLoading: boolean,
  feedItems: ActivityProps['feedItems'],
  shares: ActivityProps['shares'],
  watchedBy: ActivityProps['watchedBy'],
  watchlistBy: ActivityProps['addedToWatchlistBy'],
  isVisible: boolean,
}


export const Tvshow: FC<TvshowProps> = memo(function Tvshow({ canonical, share, shareLoading, feedItems, shares, watchedBy, watchlistBy, isVisible }: TvshowProps) {

  const { user } = useSession();

  const { data: senderData } = useContact(share?.sender?._id);
  const sender = senderData?.contact;

  const isInWatchlist = useIsInWatchlist(share?._id, canonical._id);
  const isWatched = useIsWatched(share?._id, canonical._id);

  const tvshow = canonical.tvshow;

  const rating = canonical.rating || share?.rating || undefined;
  const ratings = canonical.ratings || share?.ratings || [];
  const description = share?.message?.text ? `${sender?.username}: "${truncate(share.message.text, 20).trim()}"` : `${truncate(tvshow.overview || '', 40).trim()}`;
  const imageUrl = share?.unfurl?.images?.[0] ? `${process.env.REACT_APP_IMAGE_BASE}/${share.unfurl.images[0].hash}_512x512.jpg` : tvshow.poster ? `${process.env.REACT_APP_IMAGE_BASE}/${tvshow.poster.hash}_512x512.jpg` : undefined;
  const title = `${(share && sender) ? sender.username + ' shared ' : ''}${truncate(tvshow.title, 30)} • Ice Cream Club`;

  const jsonLd = (share && sender) ? createSharePageType(share, canonical, sender) : createCanonicalPageType(canonical);

  return (
    <Layout
      title={
        <>
          <Typography variant='h2' component='h1' sx={{ color: 'white', lineHeight: '28px', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, lineClamp: 2, textOverflow: 'ellipsis', overflowWrap: 'break-word', textWrap: 'balance' }}>
            <LinkBox href={canonicalLink(canonical._id, undefined)}>
              {tvshow.title}
            </LinkBox>
          </Typography>
          <Typography variant='h4' noWrap sx={{ color: 'white', lineHeight: '24px', fontWeight: 400, mb: 1 }}>
            <TypeYear canonical={canonical} />
          </Typography>
          {((tvshow.imdbRating && tvshow.imdbRating > 0) || rating) &&
            <Box sx={{ display: 'flex', height: 24, gap: 1 }}>
              {tvshow.imdbRating && tvshow.imdb && tvshow.imdbRating > 0 &&
                <Imdb key='imdb' imdbId={tvshow.imdb} rating={tvshow.imdbRating} sx={{ height: '24px', color: 'white', alignItems: 'center' }} />
              }
              {rating &&
                <Box sx= {{ display: 'flex', alignItems: 'center' }}>
                  <Avatar contact={user?.contact} size='small' />
                  <RatingIcon value={rating.rating} highlight={true} width='20px' style={{ position: 'relative', marginLeft: -5 }} />
                  <Typography variant='body2' sx={{ ml: '3px', color: 'white'  }}>
                    {rating.rating}<Box component='span' sx={{ color: 'grey.400' }}>/5</Box>
                  </Typography>
                </Box>
              }
            </Box>
          }
        </>
      }
      headerTitle={tvshow.title}
      backdrop={tvshow.backdrop}
      poster={tvshow.poster}
      video={tvshow.video}
      share={share || undefined}
      canonical={canonical}
      watchedBy={watchedBy}
      watchlistBy={watchlistBy}
      isVisible={isVisible}
    >
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title}/>
        <meta name="author" content={sender?.name || ''} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl || "https://app.icecream.club/kawaii.png"} />
        <link rel="canonical" href={`https://app.icecream.club${canonicalLink(canonical._id, share?._id)}`} />
        <script type="application/ld+json">{jsonLd && JSON.stringify(jsonLd)}</script>
      </Helmet>

      <Box>

        {share &&
          <Box sx={{ backgroundColor: 'background.paper' }}>
            <Post share={share} canonical={canonical} showAllComments={true} />
            <Divider sx={{ width: '100%' }} />
          </Box>
        }
        {shareLoading && !share &&
          <Box sx={{ backgroundColor: 'background.paper' }}>
            <PostLoading />
            <Divider sx={{ width: '100%' }} />
          </Box>
        }

        <ReshareButton share={share} canonical={canonical} />

        <Activity canonical={canonical} share={share} watchedBy={watchedBy || []} isWatched={isWatched} addedToWatchlistBy={watchlistBy || []} isInWatchlist={isInWatchlist} feedItems={feedItems} shares={shares} rating={rating} ratings={ratings} />

        <Providers providerItems={tvshow.providers} providersCountry={tvshow.providersCountry || ''} release={tvshow.firstAirDate} />

        <Overview overview={tvshow.overview} />

        <Details
          canonicalId={canonical._id}
          imdb={tvshow.imdb}
          homepage={tvshow.homepage}
          creators={tvshow.createdBy}
          directors={tvshow.directors}
          genres={tvshow.genres}
          networks={tvshow.networks}
          release={tvshow.firstAirDate}
          seasons={tvshow.seasons}
          originalTitle={tvshow.originalTitle || ''}
          originalLanguage={tvshow.originalLanguage || ''}
          title={tvshow.title}
        />

        <Cast canonicalId={canonical._id} />

      </Box>

      <Delayed waitBeforeShow={500}>
        <Trailer video={tvshow.video} />

        <ExternalContents canonicalId={canonical._id} />

        <OtherCanonicals canonicalId={canonical._id} />

        {share && share.sender?._id &&
          <MoreShares contact={share.sender} share={share} />
        }

        <SiteindexLinks canonicalContent={canonical} />

      </Delayed>

    </Layout>
  );
});
