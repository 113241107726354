import { FC, SyntheticEvent, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { Box } from '@mui/material';

import { CanonicalContent, Share } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { useLoginPush } from '../../hooks/login-push';
import { MoreSheet } from '../../components/Post/MoreSheet';
import { ArrowRight } from '../../components/icons/ArrowRight';
import { Button } from '../../components/Button';
import { Divider } from './Divider';


export type ReshareButtonProps = {
  share?: Pick<Share, '_id'> | null,
  canonical?: Pick<CanonicalContent, '_id'> | null,
};


export const ReshareButton: FC<ReshareButtonProps> = function ReshareButton({ share, canonical }) {
  const { track } = useAnalytics();
  const { user } = useSession();
  const { setLoginHint } = useLoginPush();

  const [sheetOpen, setSheetOpen] = useState(false);

  const onClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setSheetOpen(true);
      track('reshare_button', { category: 'share', origin: 'canonical' });
    } else {
      track('click_share_canonical', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to share this.');
    }
  };

  const onClose = (ev?: SyntheticEvent) => {
    if(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setSheetOpen(false);
  };


  return (
    <>
      <Box sx={{ p: 2 }}>
        <Button color="primary" variant="contained" fullWidth sx={{  }} onClick={onClick}>
          <ArrowRight style={{ height: 14, marginLeft: -8, marginRight: 4, flex: '0 0 auto' }} />
          Share with friends
        </Button>
      </Box>
      <Divider sx={{ px: 2 }} />

      {sheetOpen &&
        <MoreSheet share={share} canonical={canonical} onClose={onClose} variant='share' />
      }
    </>
  );
}
