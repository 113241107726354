import { useState, useEffect, FC, ReactNode } from 'react';
import { isServer, isHydrating } from '../utils/detect';


export type DelayedProps = {
  children: ReactNode;
  waitBeforeShow?: number;
};


const Delayed: FC<DelayedProps> = ({ children, waitBeforeShow=500 }) => {
  const [isShown, setIsShown] = useState(isServer || isHydrating);

  useEffect(() => {
    if(isShown) return;

    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow, isShown]);

  return isShown ? children : null;
};

export default Delayed;
