import { useEffect, useRef } from "react";
import { useParams } from "react-router";


export function useParamsDelayed<T extends ReturnType<typeof useParams>>(delayAfterUnset: number = 500) {
  const params = useParams<T>();

  // Use refs to preserve page during transition
  const refs = useRef(params);

  // modify all refs if one of the params changes, but not if all are unset
  const allUnset = Object.values(params).every(value => !value);
  const someChanged = Object.entries(params).some(([key, value]) => value !== refs.current[key]);
  if(!allUnset && someChanged) {
    refs.current = params;
  }

  // unset refs after transition
  useEffect(() => {
    if(Object.values(refs.current).every(value => !value)) {
      const timeoutRef = setTimeout(() => {
        refs.current = params;
      }, delayAfterUnset);
      return () => clearTimeout(timeoutRef);
    }
  }, [params, delayAfterUnset]);

  return refs.current;
}
